import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environement } from '@environments/environment';
import { ApiResponse } from '@models/common/api-response.model';
import { Category, CategoryList } from '@models/question/category.model';
import { BaseService } from '@services/base.service';
import { MessageService } from 'primeng/api';
import { Observable, of, tap } from 'rxjs';
import { PageRequest, PageResponse } from 'pf-ui';
import { PageableResponse } from '@models/common/pageable-response.model';
import { ThemeSetting } from '@models/question/themeSetting.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends BaseService {
  signalCurrent = signal<Category | null>(null);
  signalList = signal<ApiResponse<CategoryList> | null>(null);
  signalCategoryList = signal<Category[] | null>(null);
  signalCategoriesSettingList = signal<PageResponse<Category> | undefined>(
    undefined,
  );

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
  ) {
    super(http, messageService);
  }

  create(name: string): Observable<Category> {
    return this.executeRequest(
      this.http.post<Category>(`${environement.BACKEND_URL}/categories`, {
        name: name,
      }),
    );
  }

  get(id: string): Observable<Category> {
    return this.executeRequest(
      this.http.get<Category>(
        `${environement.BACKEND_URL}/setting/category/${id}`,
      ),
    ).pipe(tap((val) => this.signalCurrent.set(val)));
  }

  getCategoryInfo(id: string): Observable<Category> {
    return this.executeRequest(
      this.http.get<Category>(
        `${environement.BACKEND_URL}/setting/category/view/${id}`,
      ),
    ).pipe(tap((val) => this.signalCurrent.set(val)));
  }

  list(): Observable<ApiResponse<CategoryList>> {
    return this.executeRequest(
      this.http.get<ApiResponse<CategoryList>>(
        `${environement.BACKEND_URL}/category?sort=name`,
      ),
    ).pipe(tap((val) => this.signalList.set(val)));
  }

  listAllCategories(): Observable<Category[]> {
    return this.executeRequest(
      this.http.get<Category[]>(`${environement.BACKEND_URL}/categories`),
    ).pipe(tap((val) => this.signalCategoryList.set(val)));
  }

  listCategoriesSetting(
    event: PageRequest,
  ): Observable<PageResponse<Category>> {
    return this.executeRequest(
      this.http.get<PageableResponse<Category>>(
        `${environement.BACKEND_URL}/setting/category`,
        {
          params: this.getHttpParams(event),
        },
      ),
    ).pipe(
      this.mapPageableResponseToPageResponse<Category>(event),
      tap((pageResponse) => {
        this.signalCategoriesSettingList.set(pageResponse);
      }),
    );
  }

  delete(category: Category): Observable<Category> {
    return this.executeRequest(
      this.http.delete<Category>(
        `${environement.BACKEND_URL}/setting/category/${category.id}`,
      ),
    );
  }

  patch(category: Category): Observable<Category> {
    return this.executeRequest(
      this.http.patch<Category>(
        `${environement.BACKEND_URL}/categories/${category.id}`,
        category,
      ),
    ).pipe(
      tap(() => {
        this.signalCurrent.set(category);
      }),
    );
  }

  patchSetting(category: Category): Observable<Category> {
    return this.executeRequest(
      this.http.patch<Category>(
        `${environement.BACKEND_URL}/setting/category/${category.id}`,
        category,
      ),
    ).pipe(
      tap(() => {
        this.signalCurrent.set(category);
      }),
    );
  }

  postSerieCombinations(
    category: Category | null,
    themeSetting?: ThemeSetting[],
  ): Observable<bigint> {
    if (!category || !themeSetting) return of(BigInt(0));
    return this.executeRequest(
      this.http.post<bigint>(
        `${environement.BACKEND_URL}/setting/category/${category.id}`,
        themeSetting,
      ),
    );
  }
}
